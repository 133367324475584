<template>
	<div>
		<Header></Header>
		<div class="common-header">
			<div class="search-healder">
				<div class="logo-box">
					<img @click="toUrl('/')" class="logo" src="../../image/index/logo.png" />
				</div>
				<div class="search">
					<input placeholder="请输入商品名称" v-model="name" />
					<div class="search-btn el-icon-search" @click="search()"> </div>
				</div>
			</div>
		</div>
		<div class="apply-content">
			<div class="step">
				<el-steps :active="active" finish-status="success" simple>
					<el-step title="店铺信息"></el-step>
					<el-step title="主体信息"></el-step>
					<el-step title="经营者信息"></el-step>
				</el-steps>
				<div class="form-box">
					<el-form ref="form" :model="form" label-width="120px" size="medium">
						<el-form-item label="店铺名称" v-if="active==1">
							<el-input v-model="form.shopName" placeholder="请输入要申请的平台店铺名称"></el-input>
						</el-form-item>
						<el-form-item label="客服电话" v-if="active==1">
							<el-input v-model="form.serverPhone" placeholder="请输入客服电话"></el-input>
						</el-form-item>
						<el-form-item label="店铺负责人" v-if="active==1">
							<el-input v-model="form.headName" placeholder="请输入店铺负责人姓名"></el-input>
						</el-form-item>
						<el-form-item label="负责人电话" v-if="active==1">
							<el-input v-model="form.headPhone" placeholder="请输入负责人电话"></el-input>
						</el-form-item>
						<el-form-item label="店铺地址" v-if="active==1">
							<el-input v-model="form.shopAddr" placeholder="请输入实体店详细店铺地址"></el-input>
						</el-form-item>
						<el-form-item label="企业名称" v-if="active==2">
							<el-input v-model="form.marchantName" placeholder="请输入企业名称"></el-input>
						</el-form-item>
						<el-form-item label="社会信用代码" v-if="active==2">
							<el-input v-model="form.socialCreditCode" placeholder="请输入社会信用代码"></el-input>
						</el-form-item>
						<el-form-item label="营业执照" v-if="active==2">
							<imageUpload v-model="form.businessLicenses" :limit="1" />
						</el-form-item>
						<el-form-item label="药品经营许可证" v-if="active==2">
							<imageUpload v-model="form.drugAuths" :limit="1" />
						</el-form-item>
						<el-form-item label="食品经营许可证" v-if="active==2">
							<imageUpload v-model="form.foodAuths" :limit="1" />
						</el-form-item>
						<el-form-item label="其他资质材料" v-if="active==2">
							<imageUpload v-model="form.otherAuths" :limit="5" />
						</el-form-item>

						<el-form-item label="经营者姓名" v-if="active==3">
							<el-input v-model="form.authManagerName" placeholder="请输入经营者姓名"></el-input>
						</el-form-item>
						<el-form-item label="经营者身份证号" v-if="active==3">
							<el-input v-model="form.authManagerCard" placeholder="请输入经营者身份证号"></el-input>
						</el-form-item>
						<el-form-item label="身份证头像面" v-if="active==3">
							<imageUpload v-model="form.authManagerCardSides" :limit="1" />
						</el-form-item>
						<el-form-item label="身份证国徽面" v-if="active==3">
							<imageUpload v-model="form.authManagerCardFronts" :limit="1" />
						</el-form-item>
						<el-form-item label="手持证件照" v-if="active==3">
							<imageUpload v-model="form.authManagerCardHeads" :limit="1" />
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="next(true)" v-if="active<3">下一步</el-button>
							<el-button type="" @click="next(false)" v-if="active>1">上一步</el-button>
							<el-button type="primary" @click="submit()" :loading="submitLoading"
								v-if="active==3">{{submitLoading?'申请提交中':'提交'}}</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		<Footer></Footer>

	</div>
</template>

<script>
	import {
		shopApplyAdd,
		shopApplyEdit,
		shopApplyInfo
	} from '@/api/shop/apply'
	export default {
		data() {
			return {
				name: '',
				active: 1,
				form: {
					shopName: '',
					serverPhone: '',
					headName: '',
					headPhone: '',
					shopAddr: '',
					authManagerName: '',
					authManagerCard: '',
					authManagerCardFront: '',
					authManagerCardSide: '',
					authManagerCardHead: '',
					businessLicense: '',
					socialCreditCode: '',
					marchantName: '',
					type: 4,
					status: 0,
					businessLicenses: [],
					authManagerCardFronts: [],
					authManagerCardSides: [],
					authManagerCardHead: [],
					drugAuth: '',
					drugAuths: [],
					foodAuth: '',
					foodAuths: [],
					otherAuth: '',
					otherAuths: []
				},
				submitLoading: false
			};
		},
		created() {
			console.log(this.$route)
			if (this.$route.query.id) {
				this.getInfo()
			}
		},
		methods: {
			search() {
				this.$router.push({
					path: '/item/search?name=' + this.name
				})
			},
			toUrl(url) {
				this.$router.push({
					path: url
				})
			},
			next(tag) {
				if (tag) {
					this.active = this.active + 1
				} else {
					this.active = this.active - 1
				}
			},
			getInfo() {
				shopApplyInfo({
					id: this.$route.query.id
				}).then(res => {
					var data = res.data
					data.businessLicenses = [{
						name: data.businessLicense,
						url: data.businessLicense
					}]
					data.authManagerCardSides = [{
						name: data.authManagerCardSide,
						url: data.authManagerCardSide
					}]
					data.authManagerCardFronts = [{
						name: data.authManagerCardFront,
						url: data.authManagerCardFront
					}]
					data.authManagerCardHeads = [{
						name: data.authManagerCardHead,
						url: data.authManagerCardHead
					}]
					data.drugAuths = [{
						name: data.drugAuth,
						url: data.drugAuth
					}]
					data.foodAuths = [{
						name: data.foodAuth,
						url: data.foodAuth
					}]
					data.otherAuths = []
					if (data.otherAuth) {
						var pics = data.otherAuth.split(',')
						pcis.forEach(pic => {
							data.otherAuths.push({
								name: pic,
								url: pic
							})
						})
					}
					this.form = data
				})
			},
			submit() {
				if (this.submitLoading) {
					return
				}
				var data = this.form
				if (!data.shopName) {
					this.$message({
						message: '请输入店铺名称',
						type: 'error'
					});
					this.active = 1
					return
				}
				if (!data.serverPhone) {
					this.$message({
						message: '请输入客服电话',
						type: 'error'
					});
					this.active = 1
					return
				}
				if (!data.headName) {
					this.$message({
						message: '请输入店铺负责人',
						type: 'error'
					});
					this.active = 1
					return
				}
				if (!data.headPhone) {
					this.$message({
						message: '请输入负责人电话',
						type: 'error'
					});
					this.active = 1
					return
				}
				if (!data.shopAddr) {
					this.$message({
						message: '请输入店铺地址',
						type: 'error'
					});
					this.active = 1
					return
				}
				if (!data.marchantName) {
					this.$message({
						message: '请输入企业名称',
						type: 'error'
					});
					this.active = 2
					return
				}
				if (!data.socialCreditCode) {
					this.$message({
						message: '请输入社会信用代码',
						type: 'error'
					});
					this.active = 2
					return
				}
				if (data.businessLicenses.length == 0) {
					this.$message({
						message: '请上传营业执照',
						type: 'error'
					});
					this.active = 2
					return
				}
				if (data.drugAuths.length == 0) {
					this.$message({
						message: '请上传药品经营许可证',
						type: 'error'
					});
					this.active = 2
					return
				}
				if (data.foodAuths.length == 0) {
					this.$message({
						message: '请上传食品经营许可证',
						type: 'error'
					});
					this.active = 2
					return
				}
				if (!data.authManagerName) {
					this.$message({
						message: '请输入经营者姓名',
						type: 'error'
					});
					this.active = 3
					return
				}
				if (!data.authManagerCard) {
					this.$message({
						message: '请输入经营者身份证号',
						type: 'error'
					});
					this.active = 3
					return
				}
				if (data.authManagerCardSides.length == 0) {
					this.$message({
						message: '请上传身份证头像面',
						type: 'error'
					});
					this.active = 3
					return
				}
				if (data.authManagerCardFronts.length == 0) {
					this.$message({
						message: '请上传身份证国徽面',
						type: 'error'
					});
					this.active = 3
					return
				}
				if (data.authManagerCardHeads.length == 0) {
					this.$message({
						message: '请上传手持证件照',
						type: 'error'
					});
					this.active = 3
					return
				}
				data.businessLicense = data.businessLicenses[0].url
				data.authManagerCardSide = data.authManagerCardSides[0].url
				data.authManagerCardFront = data.authManagerCardFronts[0].url
				data.authManagerCardHead = data.authManagerCardHeads[0].url
				data.drugAuth = data.drugAuths[0].url
				data.foodAuth = data.foodAuths[0].url
				if (data.otherAuths.length > 0) {
					var pic = ''
					data.otherAuths.forEach(item => {
						pic = +(item.url + ",")
					})
					data.otherAut = pic
				}
				this.submitLoading = true
				if (data.id) {
					shopApplyEdit(data).then(res => {
						this.submitLoading = false
						this.$message({
							message: '申请已提交',
							type: 'success'
						});
						this.toUrl('/apply/list')
					})
				} else {
					shopApplyAdd(data).then(res => {
						this.submitLoading = false
						this.$message({
							message: '申请已提交',
							type: 'success'
						});
						this.toUrl('/apply/list')
					})
				}
			}
		},
	};
</script>

<style lang='scss'>
	.apply-content {
		width: 80%;
		margin-left: 10%;

		.step {
			width: 60%;
			margin-left: 20%;
			margin-top: 20px;
		}

		.form-box {
			margin-top: 20px;
		}
	}
</style>